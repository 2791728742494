import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link, useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

import { getItem, savePhotos, updateItem, uploadPhoto, deletePhoto } from "api";
import { lightboxOptions } from "defs";

import styles from "./styles.module.css";

const comparePhotosOrder = (a, b) => {
  const aOrder = a.order || 99999;
  const bOrder = b.order || 99999;
  if (aOrder < bOrder) {
    return -1;
  }
  if (bOrder > aOrder) {
    return 1;
  }
  return 0;
};

const ManagePhotos = () => {
  const { listingId } = useParams();

  const [existingPhotos, setExistingPhotos] = useState([]);
  const [photoHero, setPhotoHero] = useState();
  const [photosForUpload, setPhotosForUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirm, setShowConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [orderSaved, setOrderSaved] = useState(false);

  useEffect(async () => {
    const itemFromDb = await getItem(listingId);
    setExistingPhotos(itemFromDb.photos.sort(comparePhotosOrder));
    setPhotoHero(itemFromDb.photo_hero);
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    let photosUploaded = [];

    // Because a loop will run these simultaneously, we're uploading one at a time.

    const uploadPhotos0 = await uploadPhoto(photosForUpload[0]);
    photosUploaded.push(uploadPhotos0.filename);

    if (photosForUpload.length >= 2) {
      const uploadPhotos1 = await uploadPhoto(photosForUpload[1]);
      photosUploaded.push(uploadPhotos1.filename);
    }

    if (photosForUpload.length >= 3) {
      const uploadPhotos2 = await uploadPhoto(photosForUpload[2]);
      photosUploaded.push(uploadPhotos2.filename);
    }

    if (photosForUpload.length >= 4) {
      const uploadPhotos3 = await uploadPhoto(photosForUpload[3]);
      photosUploaded.push(uploadPhotos3.filename);
    }

    if (photosForUpload.length >= 5) {
      const uploadPhotos4 = await uploadPhoto(photosForUpload[4]);
      photosUploaded.push(uploadPhotos4.filename);
    }

    if (photosForUpload.length >= 6) {
      const uploadPhotos5 = await uploadPhoto(photosForUpload[5]);
      photosUploaded.push(uploadPhotos5.filename);
    }

    if (photosForUpload.length >= 7) {
      const uploadPhotos6 = await uploadPhoto(photosForUpload[6]);
      photosUploaded.push(uploadPhotos6.filename);
    }

    if (photosForUpload.length >= 8) {
      const uploadPhotos7 = await uploadPhoto(photosForUpload[7]);
      photosUploaded.push(uploadPhotos7.filename);
    }

    if (photosForUpload.length >= 9) {
      const uploadPhotos8 = await uploadPhoto(photosForUpload[8]);
      photosUploaded.push(uploadPhotos8.filename);
    }

    if (photosForUpload.length >= 10) {
      const uploadPhotos9 = await uploadPhoto(photosForUpload[9]);
      photosUploaded.push(uploadPhotos9.filename);
    }

    if (photosUploaded.length === Math.min(photosForUpload.length, 10)) {
      const newPhotosArray = photosUploaded.map((filename, index) => ({ filename, order: index + 1 }));
      const existingPhotosArray = existingPhotos.map((file, index) => ({ filename: file.filename, order: file.order ? newPhotosArray.length + file.order : newPhotosArray.length + index + 1}));
      const photosArray = newPhotosArray.concat(existingPhotosArray);
      const addPhotosToDb = await savePhotos(photosArray, listingId);
      if (addPhotosToDb.success) {
        if (!photoHero) {
          const newPhotoHero = photosUploaded[0];
          const saveNewPhotoHero = await updateItem({ _id: listingId, photo_hero: newPhotoHero }, listingId);
          if (saveNewPhotoHero.success) {
            setPhotoHero(newPhotoHero);
          }
        }
        setIsUploading(false);
        setPhotosForUpload([]);
        setExistingPhotos(photosArray);
        photosUploaded = [];
      } else {
        setErrorMessage(addPhotosToDb.error || "There was an error.");
      }
    }
  };

  const handleDelete = (photoId) => {
    setShowConfirm(photoId);
  };

  const handleConfirmDelete = async (filename) => {
    const newPhotosArray = existingPhotos.filter(
      (photo) => photo.filename != filename
    );
    const savePhotosToDb = await savePhotos(newPhotosArray, listingId);
    const deleteFromSpace = await deletePhoto(filename);

    if (deleteFromSpace.deleted && savePhotosToDb.success) {
      setExistingPhotos(newPhotosArray.sort(comparePhotosOrder));
    } else {
      setErrorMessage(
        savePhotosToDb.error || deleteFromSpace.error || "There was an error."
      );
    }
  };

  const handleMakeMain = async (filename) => {
    const saveMainPhotoToDb = await updateItem({ photo_hero: filename }, listingId);
    if (saveMainPhotoToDb.success) {
      setPhotoHero(filename);
    } else {
      setErrorMessage(saveMainPhotoToDb.error || "There was an error.");
    }
  };

  const handleUpdateOrder = async (filename, e) => {
    const newPhotosArray = existingPhotos.map(photo => {
      return ({
        filename: photo.filename,
        order: (photo.filename === filename) ? e.target.value : photo.order
      });
    });
  
    setExistingPhotos(newPhotosArray);
    const saveOrderToDb = await savePhotos(newPhotosArray, listingId);
  
    if (saveOrderToDb.success) {
      setOrderSaved(filename);
      setTimeout(() => {
        setOrderSaved(false);
      }, 2000);
    } else {
      setErrorMessage(saveOrderToDb.error || "There was an error.");
    }
  };

  return (
    <div id="main-content-wrapper">
      <div className={styles.wrapper}>
        <div className="container">
          <div className="col-md-12">
            <Link className="btn btn-link" to={`/item/${ listingId }`} style={{paddingLeft: 0}}>Back to Item</Link>
            <h3>Photos</h3>

            <form>
              <div className="form-group">
                <h4>Add photos</h4>
                <p>
                Press ctrl (cmd on mac) or shift to select multiple files.<br />
                Select up to 10 images at a time.
                </p>
                <input className={styles["file-input"]} id="photos" name="photos" type="file" accept="image/*" multiple onChange={(e) => setPhotosForUpload(e.target.files)} />
              </div>
              <div className="form-group">
                <button className="btn btn-default" onClick={handleUpload} disabled={photosForUpload.length === 0}>Upload</button>
                {isUploading && (<span style={{ marginLeft: 8 }}>Uploading…</span>)}
              </div>
            </form>

            {errorMessage && (
              <p className="alert alert-warning">
                {errorMessage}
              </p>
            )}

            <hr/>

            <div className="gallery row">
              <SRLWrapper options={lightboxOptions}>
                {existingPhotos.map(photo => {
                  const photoId = photo.filename.replace(/\W/g, "");
                  return (
                    <div key={photoId} className={classnames("gallery-item", "col-md-3", styles["gallery-item"])} id={`photo-${ photoId }`}>
                      <figure className={styles.figure}>
                        <a href={"https://edancemarket-assets.imgix.net/"+photo.filename+"?w=1600&h=1600&fit=max"}>
                          <img src={"https://edancemarket-assets.imgix.net/"+photo.filename+"?w=400&h=400&fit=crop&crop=top"}/>
                        </a>
                      </figure>
                      <div className={styles["photo-actions"]}>
                        <div className={styles["photo-action-row"]}>
                          {photo.filename === photoHero ? (
                            <button className="btn btn-default btn-xs is_default disabled">Main</button>
                          ) : (
                            <button className="btn btn-default btn-xs is_default" onClick={() => handleMakeMain(photo.filename)}>Make Main</button>
                          )}
                        </div>
                        <div className={styles["photo-action-row"]}>
                          <input className={classnames("form-control", "input-sm", styles["order-input"])} type="number" style={{ maxWidth: 100 }} placeholder="Order #" value={photo.order} onChange={(e) => handleUpdateOrder(photo.filename, e)}/>
                          {orderSaved === photo.filename && (
                            <span style={{ fontSize: 12, marginLeft: 8 }}>Saved!</span>
                          )}
                        </div>
                        <div className={styles["photo-action-row"]}>
                          {photo.filename === photoHero ? (
                            <span style={{fontSize: 13}}>Main photo can&apos;t be deleted</span>
                          ) : (
                            <>
                              {showConfirm !== photoId && (<button className="btn btn-default btn-xs" onClick={()=>handleDelete(photoId)}>Delete</button>)}
                              {showConfirm === photoId && (
                                <>
                                  <button className="btn btn-primary btn-xs" style={{ marginRight: 8 }} onClick={()=>handleConfirmDelete(photo.filename)}>Confirm Delete</button>
                                  <button className="btn btn-default btn-xs" onClick={()=>setShowConfirm("")}>Cancel</button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );})}
              </SRLWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePhotos;
