/* eslint-disable react/prop-types */
import React, { useState } from "react";

const ShortTextField = ({ name, value, setValue, placeholder }) => {
  const [displayValue, setDisplayValue] = useState(value);

  return (
    <div className="col-md-6">
      <input className="form-control" name={name} rows={5} value={displayValue} onChange={(e) => {
        setDisplayValue(e.target.value);
        setValue(e.target.value);
      }} placeholder={placeholder} />
    </div>
  );
};

export default ShortTextField;