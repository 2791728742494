import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import { getCustomPage } from "api";

import coverImage from "./cover2.jpg";
import styles from "./styles.module.css";

const CustomPage = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState();
  useEffect(async () => {
    const customPageData = await getCustomPage(slug);
    setPageData(customPageData);
  }, []);
  return (
    <div id="main-content-wrapper">
      <div id="hero-image">
        <img src={coverImage} width="100%" />
      </div>
      <div className={styles.content}>
        <div className={classnames("container", styles.container)}>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              {pageData?.content && parse(pageData.content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPage;
