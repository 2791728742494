export const checkNumberRangeMatch = (itemValue, selectedRange) => {
  if (itemValue === undefined) {
    return true;
  }
  let number = Number(itemValue);
  const range =
    typeof selectedRange === "string"
      ? selectedRange.split("-")
      : selectedRange[0].split("-");
  return number >= range[0] && number <= range[1];
};
