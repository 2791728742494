/* eslint-disable react/prop-types */
import React, { useState } from "react";
import classnames from "classnames";

import styles from "./styles.module.css";

const RangeField = ({ name, value, setValue, prefix, suffix }) => {
  const [displayValue, setDisplayValue] = useState(value);

  return (
    <div className={classnames("input-group", styles["range-field"])}>
      {prefix && (
        <div className='input-group-addon'>{prefix}</div>
      )}
      <input className='form-control' type='number' value={displayValue} onChange={(e) => {
        setDisplayValue(e.target.value);
        setValue(e.target.value);
      }} name={name} />
      {suffix && (
        <div className='input-group-addon'>{suffix}</div>
      )}
    </div>
  );
};

export default RangeField;