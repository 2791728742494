import React, { useEffect, useState } from "react";
import App from "App";
import SimpleReactLightbox from "simple-react-lightbox";
import { getVisitorIPInfo } from "api";
import { Route, Switch, useHistory } from "react-router-dom";
import { consoleLog } from "utils/consoleLog";

export const AppWrapper = () => {
  const path = location.pathname;
  const history = useHistory();

  const [shouldShowContent, setShouldShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const blockUnwantedVisitors = async () => {
    if (path === "/500") {
      setIsLoading(false);
      return;
    }

    try {
      const geolocation = await getVisitorIPInfo();
      consoleLog(`geolocation: ${JSON.stringify(geolocation)}`);
      const country = geolocation.country_name;
      const ip = geolocation.ip;
      const countryBlockList = ["Singapore"];
      if (countryBlockList.includes(country) || ip.includes("114.119.")) {
        if (path !== "/500") {
          history.push("/500");
        }
        setIsLoading(false);
        return;
      }
    } catch (e) {
      consoleLog(`error in blockUnwantedVisitors: ${e}`, "error");
    }

    setShouldShowContent(true);
    setIsLoading(false);
  };

  useEffect(async () => {
    blockUnwantedVisitors();
  }, [path]);

  if (isLoading) {
    return null;
  } else {
    return shouldShowContent ? (
      <Switch>
        <Route path="*">
          <SimpleReactLightbox>
            <App />
          </SimpleReactLightbox>
        </Route>
      </Switch>
    ) : (
      <p>Site unavailable</p>
    );
  }
};
