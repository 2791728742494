import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  NavLink,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";

import "api/mockserver";
import { getCustomPages, checkIfSignedIn } from "api";
import CustomPage from "pages/CustomPage";
import EditPage from "Manage/EditPage";
import About from "pages/About";
import Contact from "pages/Contact";
import Items from "Items";
import ItemDetails from "Items/Details";
import EditListing from "Manage/EditListing";
import ManagePhotos from "Manage/Photos";
import SignIn from "Auth/SignIn";
import "bootstrap/css/bootstrap.min.css";

import "./styles.css";

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const [customPages, setCustomPages] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(async () => {
    document.title = "eDanceMarket - Buy and rent dancewear.";
  }, []);

  useEffect(async () => {
    const customPagesArray = await getCustomPages();
    setCustomPages(customPagesArray);
    doAuthCheck();
  }, [path]);

  const doAuthCheck = async () => {
    const authCheck = await checkIfSignedIn();
    if (authCheck === true) {
      setIsSignedIn(true);
    }
  };

  const handleSignOut = () => {
    localStorage.setItem(window.location.host, "");
    setIsSignedIn(false);
    history.push("/manage");
  };

  const isColored =
    path === "/contact" ||
    path.includes("/items") ||
    path.includes("/auth") ||
    path.includes("/manage");
  const wrapperClass = isColored ? "colored" : "white";

  return (
    <div className={`background-renderer ${wrapperClass}`}>
      <nav
        id="primary-nav"
        className="navbar navbar-default navbar-static-top navbar-inverse"
      >
        <div className="container">
          <div className="col-md-12">
            <ul className="nav navbar-nav navbar-left">
              <li>
                <NavLink to="/items" activeClassName="is-active">
                  Listings
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" activeClassName="is-active">
                  About
                </NavLink>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              {isSignedIn && (
                <>
                  <li>
                    <NavLink
                      to="/manage/listings/add"
                      activeClassName="is-active"
                    >
                      Add Listing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/manage/pages/add" activeClassName="is-active">
                      Add Page
                    </NavLink>
                  </li>
                  <li>
                    <a onClick={handleSignOut}>Sign Out</a>
                  </li>
                  <li>
                    <a>{"//"}</a>
                  </li>
                </>
              )}
              {customPages.map((page) => (
                <li key={page.slug}>
                  <NavLink to={`/p/${page.slug}`} activeClassName="is-active">
                    {page.title}
                  </NavLink>
                </li>
              ))}
              <li>
                <NavLink to="/contact" activeClassName="is-active">
                  Contact
                </NavLink>
              </li>
              <li>
                {/* eslint-disable react/jsx-no-target-blank */}
                <a
                  href="https://www.facebook.com/eDanceMarket/"
                  target="_blank"
                >
                  Like us on Facebook
                </a>
                {/* eslint-enable react/jsx-no-target-blank */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="page-header-container">
        <div className="container">
          <div className="col-md-12">
            <div className="page-header">
              <h1>
                <a href="/">
                  eDanceMarket
                  <small>Buy and rent dancewear</small>
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path="/">
          <Redirect to={{ pathname: "/items" }} />
        </Route>
        <Route path="/auth/signin">
          <SignIn />
        </Route>
        <Route path="/auth/signout">
          <div>Sign out component</div>
        </Route>
        <Route exact path="/manage">
          <Redirect to={{ pathname: "/auth/signin" }} />
        </Route>
        <Route path="/manage/listings/add">
          <EditListing />
        </Route>
        <Route path="/manage/listings/edit/:id">
          <EditListing />
        </Route>
        <Route path="/manage/pages/add">
          <EditPage />
        </Route>
        <Route path="/manage/photos/:listingId">
          <ManagePhotos />
        </Route>
        <Route path="/items">
          <Items />
        </Route>
        <Route path="/items/:categorySlug">
          <Items />
        </Route>
        <Route path="/item/:id">
          <ItemDetails />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/p/:slug">
          <CustomPage />
        </Route>
        <Route path="/404">
          <div id="main-content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6" style={{ margin: "40px 15px 30px" }}>
                  <p>
                    Page not found. <NavLink to="/">Back to homepage.</NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
      <footer>
        <div className="container">
          <p>eDanceMarket</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
