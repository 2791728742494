/* eslint-disable react/prop-types */
import React from "react";
import MultiSelect from "react-multi-select-component";

import styles from "./styles.module.css";

const SelectField = ({ options, value, setValue, multiple, isChild }) => {

  const selectOptions = options.map(option => ({
    label: option, value: option
  }));

  let parsedValue = [];
  if (typeof value === "string") {
    parsedValue = [{ label: value, value: value }];
  } else if (Array.isArray(value)) {
    parsedValue = value.map(selection => {
      if (typeof selection === "string") {
        return { label: selection, value: selection };
      } else {
        return selection;
      }
    });
  }

  const [displayValues, setDisplayValues] = React.useState(parsedValue);

  const setMultiSelectValues = (change) => {
    setDisplayValues(change);
    setValue(change.map(changeValue => changeValue.value));
  };

  const setSingleSelectOptions = (change) => {
    setDisplayValues([change[change.length-1]]);
    setValue(change.map(changeValue => changeValue.value));
  };

  const allLabel = options.join(", ");

  const disableSearch = options.length < 5;

  const field = multiple ? (
    <MultiSelect
      options={selectOptions}
      onChange={setMultiSelectValues}
      value={displayValues}
      overrideStrings={{
        allItemsAreSelected: allLabel
      }}
      disableSearch={disableSearch}
    />
  ) : (
    <MultiSelect
      options={selectOptions}
      onChange={setSingleSelectOptions}
      value={displayValues}
      overrideStrings={{
        allItemsAreSelected: allLabel
      }}
      disableSearch={true}
      hasSelectAll={false}  
      ItemRenderer={({
        checked,
        option,
        onClick,
        disabled,
      }) => (
        <div className='item-renderer'>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
            style={{display: "none"}}
          />
          <span>{option.label}</span>
        </div>
      )}  
    />
  );

  return (
    <div className={!isChild ? "col-sm-5" : null}>
      <div className={styles["select-field"]}>
        {field}
      </div>
    </div>
  );
};

export default SelectField;