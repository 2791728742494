export const checkSelectionMatch = (
  itemValue,
  selected,

  /* Should the item be matched when its value is undefined? */
  matchWhenUndefined = true
) => {
  // handle inconsistencies from the server: sell and buy mean the same thing
  const normalizeItemValue = (value) => (value === "Sell" ? "Buy" : value);

  if (itemValue === undefined) {
    return matchWhenUndefined;
  } else if (typeof itemValue === "string") {
    if (selected.includes(normalizeItemValue(itemValue))) {
      return true;
    }
    return false;
  } else {
    let included = false;
    itemValue.forEach((value) => {
      if (selected.includes(normalizeItemValue(value))) {
        included = true;
      }
    });
    return included;
  }
};
