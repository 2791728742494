import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { SRLWrapper } from "simple-react-lightbox";

import {
  getFields,
  getItem,
  checkIfSignedIn,
  deleteItem,
  deletePhoto,
} from "api";
import { lightboxOptions } from "defs";
import LoadingOverlay from "LoadingOverlay";

import styles from "./styles.module.css";

const ItemDetails = () => {
  const history = useHistory();

  const { id: itemId } = useParams();
  const [itemData, setItemData] = useState();
  const [fields, setFields] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  // For animating gallery section
  const [entered, setEntered] = useState(false);

  const [pageStatus, setPageStatus] = useState(200);

  const photosWrapper = useRef();

  const sortPhotos = (a, b) => {
    const a_order = a.order ? a.order : 99999;
    const b_order = b.order ? b.order : 99999;
    if (a_order < b_order) {
      return -1;
    }
    if (a_order > b_order) {
      return 1;
    }
    return 0;
  };

  useEffect(async () => {
    const authCheck = await checkIfSignedIn();
    setIsSignedIn(authCheck);

    const itemFromDb = await getItem(itemId);
    if (!itemFromDb._id) {
      setPageStatus(404);
    } else {
      setItemData(itemFromDb);

      const fieldsFromDb = await getFields();
      setFields(fieldsFromDb);

      setTimeout(() => {
        setEntered(true);
      }, 1000);
    }
  }, []);

  const price = (price) => {
    if (price.sell_type) {
      const forBuying =
        price.sell_type.includes("Sell") || price.sell_type.includes("Buy");
      const forRenting = price.sell_type.includes("Rent");

      if (forBuying) {
        const buyingText = price.sale_price ? (
          <>
            <strike style={{ color: "red" }}>${price.sell_price}</strike>
            <strong>&nbsp;${price.sale_price} to buy</strong>
          </>
        ) : (
          `$${price.sell_price} to buy`
        );
        const rentingText = forRenting && `, ${price.rent_price} to rent`;
        return (
          <>
            {buyingText}
            {rentingText}
          </>
        );
      }

      if (forRenting) {
        return `${price.rent_price} to rent`;
      }
    }
  };

  const getFieldLabel = (key, fieldsList) => {
    const requestedField = fieldsList.find((field) => field.slug === key);
    return requestedField && requestedField.name;
  };

  const getChildFieldLabel = (key, parentKey) => {
    const requestedParentField = fields.find(
      (field) => field.slug === parentKey
    );
    return requestedParentField && requestedParentField.content
      ? getFieldLabel(key, requestedParentField.content)
      : key;
  };

  const getFieldValue = (key, value) => {
    if (typeof value === "string") {
      return value;
    } else if (Array.isArray(value)) {
      return value.join(", ");
    } else if (typeof value === "object") {
      return Object.keys(value).map(
        (childKey) =>
          value[childKey] && (
            <span key={childKey}>
              {getChildFieldLabel(childKey, key)}: {value[childKey]}
              <br />
            </span>
          )
      );
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    if (window.confirm("Are you sure you want to delete this?")) {
      const deleteFromDb = await deleteItem(itemId);

      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      const deleteFiles = async () => {
        for (let i = 0; i < itemData.photos.length; i++) {
          await timer(1000);
          await deletePhoto(itemData.photos[i].filename);
        }
      };
      await deleteFiles();

      if (deleteFromDb.deleted) {
        history.push("/items");
      }
    }
  };

  if (pageStatus === 404) {
    return <Redirect to="/404" />;
  }

  const getPhotoUrls = (photo) => {
    return {
      // small: `https://edancemarket-assets.imgix.net/${photo.filename}?h=800`,
      // large: `https://edancemarket-assets.imgix.net/${photo.filename}?w=1600&h=1600&fit=max`,
      small: `https://edancemarket.twic.pics/${photo.filename}?twic=v1/resize=-x800`,
      large: `https://edancemarket.twic.pics/${photo.filename}?twic=v1/resize=1600x-`,
    };
  };

  return itemData ? (
    <>
      {isDeleting && (
        <LoadingOverlay text={"Deleting listing and its photos"} />
      )}
      <div id="main-content-wrapper">
        {itemData.photos && (
          <div
            className={classnames({
              [styles.scrollbox]: true,
              [styles["scrollbox-entered"]]: entered,
            })}
          >
            <div className={styles.photosWrapper} ref={photosWrapper}>
              <SRLWrapper options={lightboxOptions}>
                {itemData.photos.sort(sortPhotos).map((photo) => (
                  <a key={photo.filename} href={getPhotoUrls(photo).large}>
                    <img src={getPhotoUrls(photo).small} />
                  </a>
                ))}
              </SRLWrapper>
            </div>
          </div>
        )}
        <div className={styles["main-content"]}>
          <div className={classnames([styles.container, "container"])}>
            <div className="col-md-8">
              <div style={{ padding: 20 }}>
                {isSignedIn && (
                  <div className={styles["manage-buttons"]}>
                    <Link
                      className="btn btn-default"
                      to={`/manage/listings/edit/${itemData._id}`}
                    >
                      Edit item details
                    </Link>
                    <Link
                      className="btn btn-default"
                      to={`/manage/photos/${itemData._id}`}
                    >
                      {itemData.photos ? "Add and manage photos" : "Add photos"}
                    </Link>
                    <a className="btn btn-default" onClick={handleDelete}>
                      Delete item
                    </a>
                  </div>
                )}
                <h3>{itemData.price && price(itemData.price)}</h3>
                {itemData.comments && (
                  <div className="item-description">
                    {parse(itemData.comments)}
                  </div>
                )}
                <p className={styles.contact}>
                  Please <Link to="/contact">contact us</Link> to try or
                  purchase, and refer to the item ID{" "}
                  <code>{itemData.name}</code>.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <table className={styles["item-details"]}>
                <tbody>
                  <tr>
                    <td className={styles["td-key"]}>Item ID</td>
                    <td className={styles["td-value"]}>{itemData.name}</td>
                  </tr>
                  {Object.keys(itemData).map((key) => {
                    const excludedKeys = [
                      "_id",
                      "name",
                      "comments",
                      "photos",
                      "contact",
                      "photo_hero",
                      "category",
                      "price",
                    ];
                    if (!excludedKeys.includes(key)) {
                      return (
                        <tr key={key}>
                          <td className={styles["td-key"]}>
                            {getFieldLabel(key, fields)}
                          </td>
                          <td className={styles["td-value"]}>
                            {getFieldValue(key, itemData[key])}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ItemDetails;
