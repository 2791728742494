import React, { useEffect, useState } from "react";
import { Route, Switch, NavLink, useRouteMatch } from "react-router-dom";

import { getCategories } from "api";
import ItemsList from "Items/List";

import styles from "./styles.module.css";

const Items = () => {
  const [categories, setCategories] = useState([]);
  let { path, url } = useRouteMatch();

  useEffect(async () => {
    const categoriesFromDb = await getCategories();
    setCategories(categoriesFromDb);
  }, []);

  return (
    <>
      <div className={styles["category-nav"]}>
        <div className="container">
          <div className="col-md-12">
            <ul>
              <li>
                <NavLink to={url} activeClassName="is-active" exact>
                  All
                </NavLink>
              </li>
              {categories.map((category) => (
                <li key={category.name}>
                  <NavLink
                    to={`${url}/${category.slug}`}
                    activeClassName="is-active"
                  >
                    {category.name === "Others" ? "Pauk-art" : category.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="main-content-wrapper">
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <ItemsList categories={categories} />
            </Route>
            <Route path={`${path}/:categorySlug`}>
              <ItemsList categories={categories} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Items;