/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import MultiSelect from "react-multi-select-component";

import { checkIfSignedIn, getExtra, addItem, getItem, updateItem } from "api";
import Loading from "Manage/Loading";

import LongTextField from "Manage/fields/LongTextField";
import ShortTextField from "Manage/fields/ShortTextField";
import SelectField from "Manage/fields/SelectField";
import RangeField from "Manage/fields/RangeField";

const EditListing = () => {
  const history = useHistory();
  const { id: itemId } = useParams();

  const [isSignedIn, setIsSignedIn] = useState();
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState([]);
  const [itemType, setItemType] = useState([]);

  const [values, setValues] = useState({});
  const [message, setMessage] = useState();

  useEffect(async () => {
    const auth = await checkIfSignedIn();
    setIsSignedIn(auth);
  }, []);

  useEffect(async () => {
    const extraFromDb = await getExtra();
    setFields(extraFromDb.fields);
    setCategories(extraFromDb.categories);
  }, []);

  useEffect(async () => {
    if (itemId) {
      const itemFromDb = await getItem(itemId);
      setValues(itemFromDb);
      setItemType([{ label: itemFromDb.category.charAt(0).toUpperCase() + itemFromDb.category.slice(1), value: itemFromDb.category}]);
    }
  }, [categories]);

  const handleInputChange = (value, slug) => {
    const newValues = values;
    newValues[slug] = value;
    setValues(newValues);
  };

  const handleCategoryChange = (value) => {
    const newType = value[value.length - 1];
    setItemType([newType]);
    const newValues = values;
    newValues["category"] = newType.value;
    setValues(newValues);
  };

  const handleChildInputChange = (value, slug, parentSlug) => {
    const newValues = values;
    newValues[parentSlug] = newValues[parentSlug] || {};
    newValues[parentSlug][slug] = value;
    setValues(newValues);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (itemId) {
      // Edit item
      const updatedItem = await updateItem(values, itemId);
      if (updatedItem.success) {
        history.push(`/item/${itemId}`);
      } else if (updatedItem.error) {
        setMessage(updatedItem.error);
      } else {
        setMessage("An error occurred.");
      }
    } else {
      // Add item
      const addedItem = await addItem(values);
      if (addedItem._id) {
        history.push(`/item/${addedItem._id}`);
      } else if (addedItem.error) {
        setMessage(addedItem.error);
      } else {
        setMessage("An error occurred.");
      }
    }
  };

  const showFieldUi = ({
    field, isChild, value, handleInputChange, parentSlug }) => {

    switch (field.type) {
    case "select":
      return <SelectField
        isChild={isChild}
        name={field.slug}
        options={field.content}
        value={value}
        setValue={value => handleInputChange(value, field.slug, parentSlug)}
        multiple={field.select_multiple}
      />;
    case "range":
      return <RangeField
        name={field.slug}
        value={value}
        setValue={eventValue => handleInputChange(eventValue, field.slug, parentSlug)}
        prefix={field.prefix}
        suffix={field.suffix}
      />;
    case "text":
      return <LongTextField
        name={field.slug}
        value={value}
        setValue={e => handleInputChange(e.target.innerHTML, field.slug, parentSlug)}
      />;
    case "shorttext":
      return <ShortTextField
        name={field.slug}
        value={value}
        setValue={(eventValue) => handleInputChange(eventValue, field.slug, parentSlug)}
      />;
    case "parent":
      return field.content.map(childField => {
        const fieldCount = field.content.length;
        const columnSize = Math.floor(8 / fieldCount);
        return (
          <div key={childField.slug} className={`col-sm-${ columnSize }`}>
            {childField.name}
            {showFieldUi({
              field: childField,
              isChild: true,
              value: values[field.slug] ? values[field.slug][childField.slug] : null,
              handleInputChange: handleChildInputChange,
              parentSlug: field.slug
            })}
          </div>
        );});
    } 
  };
  
  if (isSignedIn === undefined) {
    return <Loading />;
  }
  if (isSignedIn) {
    return (
      <div id="main-content-wrapper">
        <div className="container" style={{paddingTop: 100, paddingBottom: 100}}>
          <form className="form-horizontal">
            <h3>{itemId ? "Edit" : "Add"} item</h3>
            <hr />
            {message && (
              <div role="alert" className={"alert alert-warning"}>
                <p>{message}</p>
              </div>
            )}
            <div className="form-group">
              <div className="col-sm-2"><label className="control-label" htmlFor="type" style={{paddingBottom: 4}}>Category</label></div>
              <div className="col-sm-5">
                <MultiSelect
                  options={categories ? categories.map(category => ({value: category.slug, label: category.name})) : []}
                  onChange={handleCategoryChange}
                  value={itemType}
                  disableSearch={true}
                  hasSelectAll={false}  
                  ItemRenderer={({
                    checked,
                    option,
                    onClick,
                    disabled,
                  }) => (
                    <div className='item-renderer'>
                      <input
                        type="checkbox"
                        onChange={onClick}
                        checked={checked}
                        tabIndex={-1}
                        disabled={disabled}
                        style={{display: "none"}}
                      />
                      <span>{option.label}</span>
                    </div>
                  )}  
                />
              </div>
            </div>
            {itemType.length > 0 && (
              <>
                {fields.filter(field => !field.hide_in_edit && field.categories.includes(itemType[0].value)).map(field => (
                  <div className="form-group" key={field.slug}>
                    <div className="col-sm-2">
                      <label className="control-label" htmlFor={`field-${ field.slug }`}>{field.name}</label>
                    </div>
                    {showFieldUi({
                      field: field,
                      value: values[field.slug],
                      handleInputChange: handleInputChange
                    })}
                  </div>
                ))}
                <hr />
                <div className="form-group">
                  <div className="col-sm-12">
                    <button className="btn btn-primary" onClick={handleSave}>Save</button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  }
  return <Redirect to={{ pathname: "/auth/signin" }} />;
};

export default EditListing;