/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";

import styles from "./styles.module.css";

const SelectField = ({ field, value, setValue }) => {
  return (
    <div className="form-group">
      <p className={styles["filter-title"]}>{field.name}</p>
      <Select
        options={field.content.map((option) => ({
          value: option,
          label: option,
        }))}
        isMulti
        onChange={(value) => setValue(value.map((object) => object.label))}
        defaultValue={field.content.map((string) => ({
          value: string,
          label: string,
        }))}
        value={value.map((string) => ({
          value: string,
          label: string,
        }))}
      />
    </div>
  );
};

export default SelectField;
