export const lightboxOptions = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    showCloseButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  }
};