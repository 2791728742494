import React from "react";
import classnames from "classnames";

import coverImage from "./cover.png";
import styles from "./styles.module.css";

const About = () => {
  return (
    <>
      <div id="hero-image">
        <img src={coverImage} width="100%" />
      </div>
      <div className={styles.about}>
        <div className={classnames("container", styles.container)}>
          <div className="col-md-2" />
          <div className="col-md-8">
            <h2>About</h2>
            <p id="hero-p">
              We bring you dancewear that you can try on and test out before you
              buy. With a variety of offerings to choose from, we’re sure you’ll
              be happy working with us. Look around our website and if you have
              any comments or questions, please feel free to contact us. We hope
              to see you again! Check back later for new updates to our website.
              There’s much more to come!
            </p>
            <div id="button" className={styles.button}>
              <a href="/items">Browse</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
