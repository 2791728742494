/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

const Loading = ({ text }) => {
  const [dots, setDots] = useState("..");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => {
        return dots.length === 3 ? "." : dots + ".";
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="loading-container">
      <div id="loading-backdrop" />
      <div id="loading-content">
        <div id="loading-content-inner">
          {text || "Loading"}
          {dots}
        </div>
      </div>
    </div>
  );
};

export default Loading;
