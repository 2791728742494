import React from "react";

const Loading = () => {
  return (
    <div id="main-content-wrapper">
      <div className="container">
        <div className="col-md-12" style={{ marginTop: 12 }}>
          <p>Loading&hellip;</p>
        </div>
      </div>
    </div>
  );
};

export default Loading;