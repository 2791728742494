import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { AppWrapper } from "AppWrapper";
import { CompatRouter } from "react-router-dom-v5-compat";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CompatRouter>
        <AppWrapper />
      </CompatRouter>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
