import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { addCustomPage, updateCustomPage } from "api";

import ShortTextField from "Manage/fields/ShortTextField";
import LongTextField from "Manage/fields/LongTextField";

const EditPage = () => {
  const { id: pageId } = useParams();
  const history = useHistory();

  const [title, setTitle] = useState();
  const [slug, setSlug] = useState();
  const [content, setContent] = useState();

  const [message, setMessage] = useState();

  const handleSave = async (e) => {
    e.preventDefault();
    const page = {
      title,
      slug,
      content,
    };
    if (pageId) {
      // Editing
      const updatedPage = await updateCustomPage(page, pageId);
      if (updatedPage.success) {
        history.push(`/page/${pageId}`);
      } else if (updatedPage.error) {
        setMessage(updatedPage.error);
      } else {
        setMessage("An error occurred.");
      }
    } else {
      // Add new
      const newPage = await addCustomPage(page);
      if (newPage._id) {
        history.push(`/page/${newPage.page.slug}`);
      } else if (newPage.error) {
        setMessage(newPage.error);
      } else {
        setMessage("An error occurred.");
      }
    }
  };

  return (
    <div id="main-content-wrapper">
      <div
        className="container"
        style={{ paddingTop: 100, paddingBottom: 100 }}
      >
        <form className="col-md-12 form-horizontal">
          <h3>{pageId ? "Edit" : "Add"} page</h3>
          <hr />
          {message && (
            <div role="alert" className={"alert alert-warning"}>
              <p>{message}</p>
            </div>
          )}
          <div className="form-group">
            <div className="col-sm-2">
              <label htmlFor="title" className="control-label">
                Title
              </label>
            </div>
            <ShortTextField
              name="title"
              value={title}
              setValue={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <div className="col-sm-2">
              <label htmlFor="slug" className="control-label">
                Slug
              </label>
            </div>
            <ShortTextField
              name="slug"
              value={slug}
              setValue={(e) => setSlug(e.target.value)}
              placeholder={
                "url-name-with-no-spaces (auto generated if left blank)"
              }
            />
          </div>
          <div className="form-group">
            <div className="col-sm-2">
              <label htmlFor="content" className="control-label">
                Content
              </label>
            </div>
            <LongTextField
              name="content"
              setValue={(e) => {
                setContent(e.target.innerHTML);
              }}
            />
          </div>
          <hr />
          <div className="form-group">
            <div className="col-sm-2" />
            <div className="col-sm-10">
              <button className="btn btn-primary" onClick={handleSave}>
                Save Page
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPage;
