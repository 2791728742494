import { consoleLog } from "./consoleLog";

export async function fetchWithTimeout(endpoint, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(endpoint, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } catch (e) {
    consoleLog(`fetchWithTimeout error: ${e}`, "error");
  }
}
