import { consoleLog } from "utils/consoleLog";
import { fetchWithTimeout } from "utils/fetchWithTimeout";

export const getCustomPage = async (slug) => {
  const response = await fetch(`/server/page/view/${slug}`);
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const getCustomPages = async () => {
  const response = await fetch("/server/pages/view");
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const addCustomPage = async (pageData) => {
  const response = await fetch("/server/page/new", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ page: pageData }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const updateCustomPage = async (updates, docId) => {
  const response = await fetch(`/server/page/update/${docId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ updates: updates }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const getExtra = async () => {
  const response = await fetch("/server/extra/view");
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const getCategories = async () => {
  const extra = await getExtra();
  return extra.categories;
};

export const getFields = async () => {
  const extra = await getExtra();
  return extra.fields;
};

export const getItems = async () => {
  const response = await fetch("/server/items/view");
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const getItem = async (itemId) => {
  const response = await fetch(`/server/item/view/${itemId}`);
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const addItem = async (itemData) => {
  const response = await fetch("/server/item/new", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ itemData }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const updateItem = async (payload, docId) => {
  // eslint-disable-next-line no-unused-vars
  const { _id, ...updates } = payload;

  const response = await fetch(`/server/item/update/${docId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ updates }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const deleteItem = async (docId) => {
  const response = await fetch(`/server/item/delete/${docId}`, {
    method: "DELETE",
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const uploadPhoto = async (photo) => {
  const formData = new FormData();
  formData.append("photo", photo);

  const response = await fetch("/server/photo/new", {
    method: "PUT",
    body: formData,
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const deletePhoto = async (filename) => {
  const response = await fetch("/server/photo/delete", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filename }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const savePhotos = async (photoArray, docId) => {
  const response = await fetch("/server/photos/save", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ photos: photoArray, docId: docId }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const signIn = async (username, password) => {
  const response = await fetch("/server/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};

export const checkIfSignedIn = async () => {
  const response = await fetch(
    `/server/checkAuth?authString=${localStorage.getItem(window.location.host)}`
  );
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json.auth;
};

export const getVisitorIPInfo = async () => {
  const response = await fetchWithTimeout("https://ipapi.co/json/");
  const json = await response.json();

  if (!response.ok) {
    const errorMessage = json && json.message;
    consoleLog(errorMessage, "error");
  }

  return json;
};
