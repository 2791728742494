/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import JoditEditor from "jodit-react";

import "./jodit-styles.css";

const LongTextField = ({ name, value, setValue }) => {
  const editor = useRef(null);
  const options = {
    toolbarButtonSize: "small",
    showXPathInStatusbar: false,
    buttons: ["bold", "italic", "source"],
    buttonsMD: ["bold", "italic"],
    buttonsXS: ["bold", "fullsize"],
  };
  return (
    <div className="col-sm-7">
      <JoditEditor config={options} name={name} ref={editor} value={value} onBlur={setValue}/>
    </div>
  );
};

export default LongTextField;