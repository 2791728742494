/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { getFields } from "api";

import SelectField from "./SelectField";
import RangeField from "./RangeField";
import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom-v5-compat";

const ItemsSidebar = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fields, setFields] = useState([]);

  const [ageGender, setAgeGender] = useState(
    searchParams.getAll("ageGender") || []
  );
  const [color, setColor] = useState(searchParams.getAll("color") || []);
  const [features, setFeatures] = useState(
    searchParams.getAll("features") || []
  );
  const [danceStyle, setDanceStyle] = useState(
    searchParams.getAll("danceStyle") || []
  );
  const [condition, setCondition] = useState(
    searchParams.getAll("condition") || []
  );
  const [size, setSize] = useState(searchParams.getAll("size") || []);
  const [rentPrice, setRentPrice] = useState(
    searchParams.get("rentPrice")?.split("-") || []
  );
  const [sellPrice, setSellPrice] = useState(
    searchParams.get("sellPrice")?.split("-") || []
  );
  const [priceType, setPriceType] = useState(
    searchParams.getAll("priceType") || []
  );

  useEffect(async () => {
    const fieldsFromDb = await getFields();
    setFields(fieldsFromDb);
    if (searchParams.size === 0) {
      resetFields(fieldsFromDb);
    }
  }, []);

  const resetFields = (fieldsList) => {
    fieldsList.map((field) => {
      field.slug === "person" && setAgeGender(field.content);
      field.slug === "color" && setColor(field.content);
      field.slug === "features" && setFeatures(field.content);
      field.slug === "dance_style" && setDanceStyle(field.content);
      field.slug === "condition" && setCondition(field.content);
      field.slug === "size" && setSize(field.content);
      field.type === "parent" &&
        field.content.map((childField) => {
          childField.slug === "sell_type" && setPriceType(childField.content);
          childField.slug === "sell_price" &&
            setSellPrice([childField.min, childField.max]);
          childField.slug === "rent_price" &&
            setRentPrice([childField.min, childField.max]);
        });
    });
  };

  const updateFilter = () => {
    setSearchParams({
      ageGender,
      color,
      features,
      danceStyle,
      condition,
      size,
      priceType,
      sellPrice: sellPrice.join("-"),
      rentPrice: rentPrice.join("-"),
    });
  };

  const clearFilter = () => {
    setSearchParams({});
    resetFields(fields);
  };

  const buttons = (
    <div className="form-group" style={{ paddingTop: 20 }}>
      <button
        className="btn btn-primary"
        onClick={updateFilter}
        style={{ marginRight: 8 }}
      >
        Apply
      </button>
      <button className="btn btn-default" onClick={clearFilter}>
        Clear
      </button>
    </div>
  );

  return (
    <div className="col-md-4">
      <div className={styles["filter-form"]}>
        {buttons}
        {fields.map((field) => (
          <div key={field.slug}>
            {field.slug === "person" && (
              <SelectField
                field={field}
                value={ageGender}
                setValue={setAgeGender}
              />
            )}
            {field.slug === "color" && (
              <SelectField field={field} value={color} setValue={setColor} />
            )}
            {field.slug === "features" && (
              <SelectField
                field={field}
                value={features}
                setValue={setFeatures}
              />
            )}
            {field.slug === "dance_style" && (
              <SelectField
                field={field}
                value={danceStyle}
                setValue={setDanceStyle}
              />
            )}
            {field.slug === "condition" && (
              <SelectField
                field={field}
                value={condition}
                setValue={setCondition}
              />
            )}
            {field.slug === "size" && (
              <SelectField field={field} value={size} setValue={setSize} />
            )}
            {field.type === "parent" &&
              field.content.map((childField) => (
                <div key={childField.slug}>
                  {childField.slug === "sell_type" && (
                    <SelectField
                      field={childField}
                      value={priceType}
                      setValue={setPriceType}
                    />
                  )}
                  {childField.slug === "sell_price" && (
                    <RangeField
                      field={childField}
                      value={sellPrice}
                      setValue={setSellPrice}
                    />
                  )}
                  {childField.slug === "rent_price" && (
                    <RangeField
                      field={childField}
                      value={rentPrice}
                      setValue={setRentPrice}
                    />
                  )}
                </div>
              ))}
          </div>
        ))}
        {buttons}
      </div>
    </div>
  );
};

export default ItemsSidebar;
