import { createServer } from "miragejs";

import extra from "./responses/extra.json";
import items from "./responses/items.json";

if (window.location.host.includes("localhost")) {
  let server = createServer({
    routes() {
      this.passthrough();
    },
  });

  const page = {
    _id: 1,
    title: "Buying & Selling",
    content: "</p>Buying and selling page</p>",
    slug: "buying-selling",
  };

  server.get("/server/pages/view", [page]);
  server.get("/server/page/view/:slug", page);
  server.put("/server/page/new", {
    _id: "abc",
    page: { title: "title", slug: "slug", content: "content" },
  });

  server.get("/server/extra/view", extra);

  server.get("/server/items/view", items);
  server.get("/server/item/view/:id", (schema, request) => {
    let id = request.params.id;
    const foundItem = items.find((item) => item._id === id);
    if (foundItem) {
      return foundItem;
    } else {
      return { error: "Cannot find item" };
    }
  });
  server.put("/server/item/new", { _id: "abc" });
  server.post("/server/item/update/:id", { success: true });
  server.delete("/server/item/delete/:id", { deleted: true });

  server.put("/server/photo/new", { success: true, filename: "test.png" });
  server.post("/server/photos/save", { success: true });
  server.delete("/server/photo/delete", { deleted: true });

  server.post("/server/auth", { auth: "string" });
  server.get("/server/checkAuth", { auth: true });

  server.get("/server/checkCaptcha", (schema, request) => {
    let captchaValue = request.queryParams.captchaValue;
    return { success: true, captchaValue: captchaValue };
  });

  server.post("https://api.emailjs.com/api/v1.0/email/send", {});
}
