/* eslint-disable react/prop-types */
import React from "react";
import Slider from "@material-ui/core/Slider";

import styles from "./styles.module.css";

const RangeField = ({ field, value, setValue }) => {
  return (
    <div className="form-group">
      <p className={styles["filter-title"]}>{field.name}</p>
      <Slider
        min={field.min}
        max={field.max}
        value={value}
        onChange={(event, newValue) => { setValue(newValue); }}
        valueLabelDisplay="auto"
        marks={[
          {value: field.min, label: `$${field.min}`},
          {value: field.max, label: `$${field.max}`},
        ]}
        classes={{
          root: styles["range"],
          colorPrimary: styles["slider-primary-color"],
          markLabel: styles["slider-labels"],
          valueLabel: styles["slider-labels"]
        }}
        className={styles.slider}
      />
    </div>
  );
};

export default RangeField;