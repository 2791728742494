/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { signIn } from "../api";
import { withRouter } from "react-router-dom";

const SignIn = ({ history }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await signIn(username, password);
    if (result.auth) {
      localStorage.setItem(window.location.host, result.auth);
      history.push("/items");
    } else {
      setFeedback({ type: "error", message: "Unable to sign in." });
    }
  };

  return (
    <div id="main-content-wrapper">
      <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
        {feedback && (
          <div role="alert" className={`alert alert-${ feedback.type }`}>
            <p>{feedback.message}</p>
          </div>
        )}
        <div className="col-md-6">
          <h2>Sign In</h2>
          <hr />
          <form method="POST" name="contact-form" id="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input className="form-control" name="username" type="text" value={username} id="username" onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input className="form-control" name="password" type="password" value={password} id="password" onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <div className="form-group">
              <button className="btn btn-primary" name="submit" id="submit">Sign in</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignIn);