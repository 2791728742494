/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Link, Redirect, useParams } from "react-router-dom";

import { getExtra, getItems } from "api";
import ItemsSidebar from "Items/Sidebar";

import styles from "./styles.module.css";
import { useSearchParams } from "react-router-dom-v5-compat";
import { checkSelectionMatch } from "./checkSelectionMatch";
import { checkNumberRangeMatch } from "./checkNumberRangeMatch";

const ItemsList = ({ categories }) => {
  const { categorySlug: urlCategorySlug } = useParams();
  const [searchParams] = useSearchParams();
  const [allItems, setAllItems] = useState([]);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchInputValue, setSearchInputValue] = useState("");

  const [pageStatus, setPageStatus] = useState(200);

  useEffect(async () => {
    const itemsFromDb = await getItems();

    // Store all items from database once so we don't have to fetch again
    setAllItems(itemsFromDb);
  }, []);

  // Once allItems has been set, we can set itemsToDisplay
  useEffect(async () => {
    if (urlCategorySlug) {
      const extraFromDb = await getExtra();
      const availableCategoriesArray = extraFromDb.categories.map(
        (category) => category.slug
      );
      if (!availableCategoriesArray.includes(urlCategorySlug)) {
        setPageStatus(404);
      } else {
        setItemsToDisplay(getRequestedItems());
      }
    } else {
      setItemsToDisplay(allItems);
    }
  }, [allItems]);

  // After itemsToDisplay has been set, we can stop loading,
  // but wait 500ms to avoid the "No Items Found" flash
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [itemsToDisplay]);

  // If anything changes, update itemsToDisplay
  useEffect(() => {
    if (urlCategorySlug) {
      setItemsToDisplay(getRequestedItems());
      setIsLoading(false);
    }
  }, [categories, urlCategorySlug, allItems, searchParams]);

  const getRequestedItems = () => {
    return allItems.filter((item) => {
      const categoryMatch = item.category === urlCategorySlug;

      // field for price in the database is inconsistent
      const price = item.price?.sale_price || item.price?.sell_price;
      const filterMatches =
        searchParams.size === 0
          ? true
          : checkSelectionMatch(
              item.person,
              searchParams.getAll("ageGender")
            ) &&
            checkSelectionMatch(item.color, searchParams.getAll("color")) &&
            checkSelectionMatch(
              item.features,
              searchParams.getAll("features")
            ) &&
            checkSelectionMatch(
              item.dance_style,
              searchParams.getAll("danceStyle")
            ) &&
            checkSelectionMatch(
              item.condition,
              searchParams.getAll("condition")
            ) &&
            checkSelectionMatch(item.size, searchParams.getAll("size")) &&
            checkSelectionMatch(
              item.price.sell_type,
              searchParams.getAll("priceType")
            ) &&
            checkNumberRangeMatch(price, searchParams.get("sellPrice")) &&
            checkNumberRangeMatch(
              item.price.rent_price,
              searchParams.get("rentPrice")
            );

      return (categoryMatch && filterMatches) || false;
    });
  };

  const getItemSummary = (item) => {
    if (item.price) {
      if (item.price.sale_price) {
        return (
          <>
            <strike style={{ color: "red" }}>
              {`$${item.price.sell_price}`}
            </strike>
            &nbsp;
            <strong>{`$${item.price.sale_price}`}</strong>
          </>
        );
      } else {
        return `$${item.price.sell_price && item.price.sell_price}`;
      }
    }
  };

  const backToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const onSearch = (event) => {
    setSearchInputValue(event.target.value);
    const searchedItems = allItems.filter((item) => {
      return item.name
        ? item.name.toLowerCase().includes(event.target.value.toLowerCase())
        : false;
    });
    setItemsToDisplay(searchedItems);
  };

  const showSidebar = Boolean(urlCategorySlug);

  if (pageStatus === 404) {
    return <Redirect to="/404" />;
  }

  const getThumbnailUrl = (photoHero) => {
    // const randomNum = Math.floor(Math.random() * 2);
    // return randomNum === 1
    //   ? `https://edancemarket.twic.pics/${photoHero}?twic=v1/focus=top/cover=1:1/resize=600x600`
    //   : `https://edancemarket-assets.imgix.net/${photoHero}?w=600&h=600&fit=crop&crop=top`;
    return `https://edancemarket-assets.imgix.net/${photoHero}?w=600&h=600&fit=crop&crop=top`;
  };

  return isLoading ? (
    <div className={classnames(["col-md-12", styles["loading-wrapper"]])}>
      <p>Loading&hellip;</p>
    </div>
  ) : (
    <>
      {showSidebar && <ItemsSidebar />}
      <div className={`col-md-${showSidebar ? 8 : 12}`}>
        <div className="form-group" style={{ marginTop: 28 }}>
          <input
            className="form-control"
            type="text"
            placeholder="Search by item ID"
            value={searchInputValue}
            onChange={onSearch}
          />
        </div>
        {itemsToDisplay.length === 0 ? (
          <h4 className={styles["no-items"]}>No items found.</h4>
        ) : (
          <>
            {/* Hide this on home page (when filter sidebar isn't visible) */}
            {showSidebar && (
              <nav>
                <ul
                  className={classnames([
                    styles["pagination-info"],
                    styles["pages-top"],
                  ])}
                >
                  <li>
                    Showing {itemsToDisplay.length} item
                    {itemsToDisplay.length != 1 && "s"}.
                  </li>
                </ul>
              </nav>
            )}
            <div className={classnames(styles["items-grid"], "row")}>
              <>
                {itemsToDisplay.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="col-sm-6 col-xs-12 col-md-4"
                      style={{ padding: 0 }}
                    >
                      <Link
                        to={`/item/${item._id}`}
                        className={styles["library-item"]}
                      >
                        <figure>
                          <span
                            className={styles["library-item-image-wrapper"]}
                          >
                            <img src={getThumbnailUrl(item.photo_hero)} />
                          </span>
                          {item.price && item.price.sale_price && (
                            <span className={styles["sale-banner"]}>Sale</span>
                          )}
                        </figure>
                        <div className={styles["item-summary"]}>
                          <h4 className="item-name">
                            {item.name ? item.name : "Untitled"}
                            <br />
                            {getItemSummary(item)}
                          </h4>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>
            </div>
            {itemsToDisplay.length > 15 && (
              <nav>
                <ul
                  className={classnames([
                    styles["pagination-info"],
                    styles["pages-bottom"],
                  ])}
                >
                  <li>
                    {itemsToDisplay.length} item
                    {itemsToDisplay.length != 1 && "s"}.
                    <br />
                    <a onClick={backToTop}>Back to top</a>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ItemsList;
