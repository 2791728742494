import React, { useState } from "react";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { consoleLog } from "utils/consoleLog";

const Contact = () => {
  const [feedback, setFeedback] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [itemId, setItemId] = useState("");
  const [message, setMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState();

  const onCaptchaChange = (value) => setCaptchaValue(value);

  const checkIfHuman = async () => {
    try {
      const captchaResult = await fetch(
        `/server/checkCaptcha?captchaValue=${captchaValue}`
      );
      const json = await captchaResult.json();
      return json.success;
    } catch (error) {
      consoleLog(`Did not pass captcha. ${error}`, "error");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFeedback("");

    window.scrollTo({ top: 0, behavior: "smooth" });

    const isHuman = await checkIfHuman();
    if (!captchaValue || !isHuman) {
      setFeedback({
        type: "warning",
        message: "Please verify that you're human.",
      });
      return;
    }

    let templateParams = {
      to_email: "info@edancemarket.com",
      from_name: name,
      from_email: email,
      item_id: itemId,
      subject: subject,
      message: message,
    };

    emailjs
      .send(
        "service_6x0pzqc", // Service ID from Email JS
        "template_ac3qdr6", // Template ID from Email JS
        templateParams,
        "user_Fih6NBqjfwEYypusIJbMR" // User API ID from Email JS
      )
      .then(
        () => {
          setFeedback({
            type: "success",
            message: "Message sent!",
          });
        },
        (error) => {
          setFeedback({
            type: "warning",
            message:
              "Error occurred. If this continues, email info@edancemarket.com directly.",
            detail: error,
          });
          consoleLog(error, "error");
        }
      );
  };

  const required = {
    name: true,
    email: true,
    subject: false,
    item: false,
    message: true,
  };

  return (
    <div id="main-content-wrapper">
      <div
        className="container"
        style={{ paddingTop: 100, paddingBottom: 100 }}
      >
        {feedback && (
          <div role="alert" className={`alert alert-${feedback.type}`}>
            <p>{feedback.message}</p>
          </div>
        )}
        <div className="col-md-6">
          <h2>Contact Us</h2>
          <hr />
          <form
            method="POST"
            name="contact-form"
            id="contact-form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <label htmlFor="from_name">First &amp; Last Name</label>
              <input
                className="form-control"
                name="from_name"
                type="text"
                value={name}
                id="from_name"
                required={required.name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="from_email">Email</label>
              <input
                className="form-control"
                name="from_email"
                type="email"
                value={email}
                id="from_email"
                required={required.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                className="form-control"
                name="subject"
                type="text"
                value={subject}
                id="subject"
                required={required.subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="item_id">
                Item ID
                <span className="required">
                  &nbsp;(please include if you&apos;re inquiring about an item)
                </span>
              </label>
              <input
                className="form-control"
                name="item_id"
                type="text"
                value={itemId}
                id="item_id"
                required={required.item}
                onChange={(e) => setItemId(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                name="message"
                rows="10"
                id="message"
                required={required.message}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="form-group">
              <ReCAPTCHA
                sitekey="6Le0e0MaAAAAADlMuK9Lb4_J7KVLciX_ut3H8Tcn"
                onChange={onCaptchaChange}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary" name="submit" id="submit">
                Send Message
              </button>
            </div>
          </form>
          <hr />
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <b>Ieva Pauksena</b>
            </li>
            <li>+1 (917) 916 1072</li>
            <li>ievap75@hotmail.com</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
